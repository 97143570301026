<template>
    <div class="graphic-questions-container">
        <TimeCounter
            :assestment-time="assestmentTime"
            @current-time="($event) => {$emit('current-time', $event)}"
            @time-over="($event) => {$emit('time-over', $event)}"
        ></TimeCounter>
        <v-card outlined class="graphic-questions px-1 py-3">
            <div class="question-container">
                <QuestionCircle
                    v-for="(question, i) of questions" :key="i"
                    :status="{...question.status}"
                    :number="i + 1"
                    :question-id="question.id"
                    @selected="changeQuestion('goTo', $event, i)"
                    class="clickeable"
                ></QuestionCircle>
            </div>
            <div class="buttons-container">
                <div>
                    <v-btn
                        text
                        small
                        color="tertiary"
                        @click="changeQuestion('previous')"
                        :disabled="disablePreviousButton"
                        :class="{'disable': disablePreviousButton}"
                    >
                        {{ $t('modules.exams.assestment.graphicQuestions.previousButton') }}
                    </v-btn>
                </div>
                <div>
                    <v-btn
                        text
                        small
                        color="tertiary"
                        @click="changeQuestion('next')"
                        :disabled="disableNextButton"
                        :class="{'disable': disableNextButton}"
                    >
                        {{ $t('modules.exams.assestment.graphicQuestions.nextButton') }}
                    </v-btn>
                </div>
            </div>
            <div class="save-btn">
                <v-btn :loading="disableSaveButton" rounded color="primary" class="mr-4" @click="notifyExamsFinish">
                    {{ (!isAdmin) ? $t('modules.exams.assestment.graphicQuestions.finishButton') : $t('modules.exams.assestment.graphicQuestions.goToReview') }}
                </v-btn>
                <Calculator></Calculator>
            </div>
        </v-card>
    </div>
</template>

<script>
    export default {
        components: {
            Calculator: () => import('./Calculator'),
            TimeCounter: () => import('./TimeCounter'),
            QuestionCircle: () => import('./QuestionCircle'),
        },
        props: {
            questions: {type: Array, required: true},
            actualQuestion: {type: Number, required: true},
            assestmentTime: {type: Number},
            disableSaveButton: Boolean
        },
        data() {
            return {
                disablePreviousButton: true,
                disableNextButton: false,
            }
        },
        computed: {
            isAdmin() {
                return this.$store.getters['commons/isUserAdmin'];
            },
        },
        methods: {
            changeQuestion(action, id = null, index = null) {
                if (action == 'next') {
                    this.$emit('checkSavedAnswer', () => { this.goToNext() })
                    this.$ga.event('Examenes Live', 'Siguiente pregunta', 'Examen abierto', 0)
                } else if (action == 'previous') {
                    this.$emit('checkSavedAnswer', () => { this.goToPrevious() })
                    this.$ga.event('Examenes Live', 'Anterior pregunta', 'Examen abierto', 0)
                } else this.$emit('checkSavedAnswer', () => { this.goToQuestion(id, index) })
            },
            goToQuestion(id, index) {
                if (id !== this.actualQuestion) {
                    this.questions.forEach((question, index) => {
                        if (question.id === this.actualQuestion) {
                            question.status.actual = false;
                        } else if (question.id === id) {
                            question.status.actual = true;
                            question.status.viewed = true;

                            if (!index) {
                                this.disablePreviousButton = true;
                                this.disableNextButton = false;
                            } else if ((index + 1) === (this.questions.length)) {
                                this.disableNextButton = true;
                                this.disablePreviousButton = false;
                            } else {
                                this.disableNextButton = false;
                                this.disablePreviousButton = false;
                            }
                        }
                    });
                }

                this.$emit('update:questions', [...this.questions]);
                this.$emit('update:actualQuestion', id)
                this.$scrollTo(`#question-${index}`, 500, {container: '#scrollable-questions', easing: 'ease-in'})
            },
            goToPrevious() {
                const index = this.questions.findIndex((question) => question.status.actual);
                if (index) {
                    this.disableNextButton = false;
                    const {id} = this.questions[index - 1];
                    this.questions[index].status.actual = false;
                    this.questions[index - 1].status.viewed = true;
                    this.questions[index - 1].status.actual = true;
                    this.$emit('update:questions', [...this.questions]);
                    this.$emit('update:actualQuestion', id)
                    this.$scrollTo(`#question-${index - 1}`, 500, {container: '#scrollable-questions', easing: 'ease-in'})

                    if (!(index - 1)) this.disablePreviousButton = true
                }
            },
            goToNext() {
                const index = this.questions.findIndex((question) => question.status.actual);
                if (index < this.questions.length - 1) {
                    this.disablePreviousButton = false;
                    const {id} = this.questions[index + 1];
                    this.questions[index].status.actual = false;
                    this.questions[index + 1].status.viewed = true;
                    this.questions[index + 1].status.actual = true;
                    this.$emit('update:questions', [...this.questions]);
                    this.$emit('update:actualQuestion', id)
                    this.$scrollTo(`#question-${index + 1}`, 500, {container: '#scrollable-questions', easing: 'ease-in'})

                    if ((index + 1) === (this.questions.length - 1)) this.disableNextButton = true
                }
            },
            notifyExamsFinish() {
                if (!this.isAdmin) {
                    const { toReview, notAnswered } = this.questions.reduce((acc, question) => {
                        if (question.status.toReview) acc.toReview++
                        else if (!question.status.answered) acc.notAnswered++
                        return acc;
                    }, {toReview: 0, notAnswered: 0});

                    if (toReview || notAnswered) {
                        this.showPopUpWarning(() => {
                            this.$emit('exams-finish');
                        }, () => {}, toReview, notAnswered);
                    } else {
                        this.$emit('exams-finish');
                    }
                } else {
                    this.$emit('exams-finish');
                }
            },
            showPopUpWarning(actionPrimaryCallBack, actionSecondaryCallback, toReview = 0, notAnswered = 0) {
                this.$store.dispatch('commons/openPopUp', {
                    title: `${(toReview && notAnswered)
                        ? `${this.$tc('modules.exams.assestment.popUp.withToReviewOrNotAnswered.titleReview', toReview, { toReview })} y ${this.$tc('modules.exams.assestment.popUp.withToReviewOrNotAnswered.titleNotAnsered', notAnswered, { notAnswered })}`
                        : (toReview)
                            ? this.$tc('modules.exams.assestment.popUp.withToReviewOrNotAnswered.titleReview', toReview, { toReview })
                            : this.$tc('modules.exams.assestment.popUp.withToReviewOrNotAnswered.titleNotAnsered', notAnswered, { notAnswered }) 
                            }, ${this.$tc('modules.exams.assestment.popUp.withToReviewOrNotAnswered.finalQuestion')}`,
                    content: this.$t('modules.exams.assestment.popUp.withToReviewOrNotAnswered.content'),
                    actionPrimary: {text: this.$t('modules.exams.assestment.popUp.withToReviewOrNotAnswered.buttonPrimary'), callback: actionPrimaryCallBack},
                    actionSecondary: {text: this.$t('modules.exams.assestment.popUp.withToReviewOrNotAnswered.buttonSecondary'), callback: actionSecondaryCallback},
                    icon: { color: 'warning', name: 'mdi-eye-off-outline' },
                    color: 'primary',
                });
            }
        },
        watch: {
            actualQuestion(value) {
                const index = this.questions.findIndex((item) => item.id === value);
                if ( index + 1 === this.questions.length) this.disableNextButton = true
                else if (index) this.disablePreviousButton = false
            }
        }
    }
</script>

<style lang="sass" scoped>
@import '../Sass/variables_exams'

.graphic-questions-container
    display: flex
    flex-direction: column

    .graphic-questions
        display: flex
        flex-direction: column
        border: $card-border
        background-color: rgba(77, 148, 255, 0.08)
        min-height: 180px
        max-height: 180px

        .question-container
            .clickeable
                cursor: pointer

        .buttons-container
            display: flex
            flex-direction: row
            width: 100%

            div
                width: 50%
                margin: .5rem 0

            div:first-child
                display: flex
                justify-content: flex-start

                button.disable
                    cursor: not-allowed !important

            div:last-child
                display: flex
                justify-content: flex-end

                button.disable
                    cursor: not-allowed !important

        .save-btn
            display: flex
            flex-direction: row
            justify-content: center
            align-items: center

            .v-input--checkbox
                .review-label
                    color: black
                    font-size: 14px
</style>
